<template>
  <div class="user-container">
    <div class="back-top">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
    </div>
    <div class="user-info">
      <div class="user-info-img">
        <img v-if="u.avatar" :src="u.avatar" alt="" srcset="">
        <img v-else src="@/accesst/index/user-default.png" alt="" srcset="">
        <!-- <div @click="edit()" class="user-info-img-btn">编辑</div> -->
      </div>
      <div>
        <div class="user-name">{{u.nickname || u.username || ' '}}</div>
        <!-- <div class="user-tip">可点击编辑一段话来表达状心情等</div> -->
        <div class="user-tip">&nbsp;</div>
      </div>
    </div>
    <div class="user-card">
      <div class="user-card-item item-bg1">
        <div class="user-card-item-t">获得积分</div>
        <div class="user-card-item-d">{{ u.point || 0}}</div>
      </div>
      <div class="user-card-item item-bg2">
        <div class="user-card-item-t">转发</div>
        <div class="user-card-item-d">{{ u.experience || 0 }}</div>
      </div>
    </div>
    <div class="container-title">
      个人信息
    </div>
    <div class="user-cells">
      <div class="user-cell">
        <div class="user-cell-l">
          <img src="@/accesst/user/name.png" alt="" srcset="">姓名
        </div>
        <div @click="nameShow = true, nickname = u.nickname || ''" class="user-cell-r">
          {{ u.nickname }}<img src="@/accesst/user/right.png" alt="" srcset="">
        </div>
      </div>
      <div class="user-cell">
        <div class="user-cell-l">
          <img src="@/accesst/user/phone.png" alt="" srcset="">手机号
        </div>
        <div @click="mShow = true, mobile = u.mobile || ''" class="user-cell-r">
          {{ u.mobile }}<img src="@/accesst/user/right.png" alt="" srcset="">
        </div>
      </div>
      <div class="user-cell">
        <div class="user-cell-l">
          <img src="@/accesst/user/sex.png" alt="" srcset="">性别
        </div>
        <div @click="sexShow = true" class="user-cell-r">
          {{ u.sex == 1 ? '男' : (u.sex == 2? '女' : '保密') }}
          <img src="@/accesst/user/right.png" alt="" srcset="">
        </div>
      </div>
      <div class="user-cell">
        <div class="user-cell-l">
          <img src="@/accesst/user/date.png" alt="" srcset="">生日
        </div>
        <div @click="birthShow = true, currentDate = new Date(u.birthday || '1990-01-01')" class="user-cell-r">
          {{ u.birthday || '未知' }}<img src="@/accesst/user/right.png" alt="" srcset="">
        </div>
      </div>
      <div v-if="false" class="user-cell">
        <div class="user-cell-l">
          <img src="@/accesst/user/user.png" alt="" srcset="">眼保仪用户
        </div>
        <div class="user-cell-r">
          未知<img src="@/accesst/user/right.png" alt="" srcset="">
        </div>
      </div>
    </div>
    <!-- 更改生日 -->
    <van-popup position="bottom" v-model="birthShow">
      <van-datetime-picker
        @cancel="birthShow = false"
        @confirm="changeBirth()"
        v-model="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        type="date"
        title="生日"
      />
    </van-popup>
    <!-- 更改性别 -->
    <van-popup position="bottom" v-model="sexShow">
      <van-picker
        title="性别"
        show-toolbar
        :columns="columns"
        @confirm="changeSex"
        @cancel="sexShow = false"
      />
    </van-popup>
    <!-- 更改手机号 -->
    <van-popup position="bottom" v-model="mShow">
      <div>
        <van-cell-group style="height: 285px;">          
          <van-field            
            label="手机号" 
            placeholder="请输入手机号"
            :maxlength="11"
            v-model="mobile"
          />
          <div class="b-group">
            <van-button @click="changeMobile()" style="width: 2rem;" size="small" type="info">确认</van-button>
            <van-button style="width: 2rem;" size="small" @click="mShow = false" type="default">取消</van-button>
          </div>
        </van-cell-group>
        <!-- <van-number-keyboard
            v-model="mobile"
            :show="mShow"
            :maxlength="11"
          /> -->
      </div>
    </van-popup>
    <!-- 更改姓名 -->
    <van-popup position="top" v-model="nameShow">
      <div>
        <van-cell-group style="height: 285px;">          
          <van-field            
            clickable
            label="姓名"
            :autofocus="true"
            maxlength="20"
            placeholder="请输入姓名"            
            v-model="nickname"
          />
          <div class="b-group">
            <van-button @click="changeName()" style="width: 2rem;" size="small" type="info">确认</van-button>
            <van-button style="width: 2rem;" size="small" @click="nameShow = false" type="default">取消</van-button>
          </div>
        </van-cell-group>      
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Icon, RadioGroup, Radio, DatetimePicker, Popup, Picker,Field, NumberKeyboard, CellGroup,Button } from 'vant';
import { getUserInfo, updatrUser } from '@/api/h5';
import moment from 'moment';
// 1男，2女
export default {
  components: {[Button.name]:Button,[Field.name]: Field,[CellGroup.name]: CellGroup, [NumberKeyboard.name]:NumberKeyboard, [Icon.name]: Icon, [RadioGroup.name]: RadioGroup, [Radio.name]: Radio, [DatetimePicker.name]: DatetimePicker, [Popup.name]: Popup, [Picker.name]: Picker},
  data() {
    return {
      currentDate: new Date(),
      birthShow: false,
      minDate: new Date('1940-01-01'),
      sexShow: false,
      columns:['男', '女', '保密'],
      mShow: false,
      mobile: '',
      nameShow: false,
      nickname: '',
      maxDate: new Date(),
      u: {}
    }
  },
  created() {
    this.init();    
  },
  methods: {
    init() {
      getUserInfo({}).then(res => {
        console.log(res, 'userInfo')
        this.u = res;
      })
    },
    edit(p) {
      updatrUser(p).then(res => {
        console.log(res, 'update');

        this.birthShow = false;
        this.sexShow = false;
        this.mShow = false;
        this.nameShow = false;
        this.init();
        this.$toast('修改成功');
      })
    },
    changeBirth() {
      this.edit({birthday: moment(this.currentDate).format('yyyy-MM-DD HH:mm:ss')})
    },
    changeSex(v) {
      console.log(v, '---');
      const sex = v== '男'? 1: (v== '女'? 2: 0);
      this.edit({sex});
    },
    changeMobile() {
      console.log(this.mobile, '=====')
      var regExp = /^1[3456789]\d{9}$/;
      if (!regExp.test(this.mobile)) {
        this.$toast('请输入正确的手机号');                
      } else {
        this.edit({mobile: this.mobile})
      }      
    },
    changeName() {
      if(this.nickname) {
        this.edit({nickname: this.nickname})
      } else {
        this.$toast('请输入姓名');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: url('../../accesst/user/bg.png') no-repeat;
  background-size: 100vw auto;
  background-position: top center;
  .b-group {
    display: flex;
    margin-top: 200px;
    justify-content: space-around;
  }
  .user-cells {
    padding: 0 .38rem;
    .user-cell {
      padding: .38rem 0 .2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:  .02rem solid #EEEEEE;
      .user-cell-r {
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        img {
          width: .23rem;
          height: .23rem;
          margin-left: .12rem;
        }
      }
      .user-cell-l {
        img {
          width: .31rem;
          height: auto;
          margin-right: .12rem;
        }
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .container-title {
    padding: .5rem .38rem 0;
    height: 42px;
    font-size: 31px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 42px;
  }
  .user-card {
    padding: .52rem .38rem 0;
    display: flex;
    justify-content: space-between;
    .item-bg1 {
      width: 3.17rem;
      height: 1.69rem;
      background: url('../../accesst/user/jf-bg.png') no-repeat;
      background-size: 3.17rem 1.69rem;
    }
    .item-bg2 {
      width: 3.17rem;
      height: 1.69rem;
      background: url('../../accesst/user/share.png') no-repeat;
      background-size: 3.17rem 1.69rem;
    }
    .user-card-item {
      width: 3.17rem;
      height: 1.69rem;

      .user-card-item-t {
        padding: .29rem .38rem .15rem;
        font-size: 23px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .user-card-item-d {
        padding-left: .38rem;
        font-size: .46rem;
        font-family: DINAlternate, DINAlternate;
        font-weight: bold;
        color: #000000;
      }
    }
  }
  .user-info {
    padding: .38rem;
    // height: 1.15rem;
    display: flex;
    gap: 10px;
    .user-name {
      padding-top: .15rem;
      font-size: .35rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .user-tip {
      padding-top: .12rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      overflow: hidden;
    }
    .user-info-img {
      position: relative;
      width: 1.15rem;
      height: 1.15rem;
      .user-info-img-btn {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 1.15rem;
        height: .27rem;
        background: url('../../accesst/user/edit-bg.png') no-repeat;
        background-size: 1.04rem .3rem;
        background-position: bottom center;
        font-size: .19rem;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .27rem;
      }
      img {
        border-radius: 50%;
        width: 1.15rem;
        height: 1.15rem;
      }
    }
  }
  .back-top {
    padding: .23rem .38rem 0;
    .back-top-icon {
      font-size: .32rem;
    }
  }
}
</style>